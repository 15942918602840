import { Badge, Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Login = ({ isOpen, onClose, onSubmit, isLoading, isLogged }) => {
  const initialRef = useRef(null)
  const finalRef = useRef(null)
  const [trackID, setTrackID] = useState('')

  const handleProceed = () => {
    if (trackID.trim() !== '') {
      onSubmit(trackID)
    } else {
      
    }
  }
const handleClose = () => {
  if (isLogged) {
    onClose()
  }
}
  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Track Your Order</ModalHeader>
        <ModalCloseButton onClick={handleClose}/>
        <ModalBody pb={6}>
          <FormControl mt={4}>
            <FormLabel>Tracking ID</FormLabel>
            <Input
              ref={initialRef}
              placeholder='Enter tracking id here'
              value={trackID}
              onChange={(e) => setTrackID(e.target.value)}
              required
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          {isLoading && <Button colorScheme='blue' mr={3} isDisabled>
            processing...
          </Button>}
          {!isLoading && <Button colorScheme='blue' mr={3} onClick={handleProceed}>
            Proceed
          </Button>}
          
          
          <Button onClick={handleClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
const Payment = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const initialRef = useRef(null)
  const finalRef = useRef(null)

  useEffect(() => {
    if (props.modalShow) {
      onOpen()
    }
  }, [props.modalShow])

  const handleProceed = () => {
    if (props.modalData.phone.trim() !== '') {
      props.onSubmit(props.modalData)
    } else {
      
    }
  }
const handleClose = () => {
  onClose()
  props.setModalShow(false)
}
  return(
    <>
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Pay To Complete Your Order(Kes {props.amount})</ModalHeader>
        <ModalCloseButton onClick={handleClose} />
        <ModalBody pb={6}>
          <FormControl mt={4}>
            <FormLabel>Phone Number</FormLabel>
            <Input
              ref={initialRef}
              placeholder='Enter You phone here (07...)'
              onChange={(e) => props.setModalData({...props.modalData, phone: e.target.value})}

              required
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          {props.isLoading && <Button colorScheme='blue' mr={3} isDisabled>
            processing...
          </Button>}
          {!props.isLoading && <Button colorScheme='blue' mr={3} onClick={handleProceed}>
            Proceed
          </Button>}
          
          
          <Button onClick={handleClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    </>
    
  )
}

const Order = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const[isLogged, setIslogged] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const[modalData, setModalData] = useState({
    phone: '',
    amount: '',
    trackingID: ''
  })
  const [orderDetails, setOrderDetails] = useState({
    trackID: '',
    service: '',
    payment: '',
    time: '',
    feedback: '',
    status: ''
  })
  const toast = useToast()
  const navigate = useNavigate()
  const processToast = (status, msg) => {
    const examplePromise = new Promise((resolve, reject) => {
      setTimeout( () => {
        if (status == "Success") {
          resolve(200)
        }else{
          reject(200)
        }
      }, 2000)
      
    })

    toast.promise(examplePromise, {
      success: { title: 'Success', description: msg },
      error: { title: 'An Error Occured', description: msg },
      loading: { title: 'Checking trackingID...', description: 'Please wait' },
    })
  }
  useEffect(() => {
    // Set the nav show to true
    props.setIsNavShow(true)
    onOpen()
  }, [])

  const fetchOrder = async(trackID) => {
    try {
      // Make an API request to the PHP backend with the tracking ID
      const response = await fetch('https://enky.xgramm.com/enky_backend/mains/verifyTrackID.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          trackID: trackID
        })
      });
      
      const result = await response.json();
      processToast(result.Status, result.Message)
      setIsLoading(false)
      if (result.Status === 'Success') {
        // Update order details with the fetched data
        setOrderDetails({
          trackID: trackID,
          service: result.Service || 'N/A',
          payment: result.Data.payment_type || 'N/A',
          time: result.Data.date || 'N/A',
          feedback: result.Data.feedback || 'No feedback available',
          status: result.Data.status || 'Unknown'
        });
        setModalData({...modalData, trackingID: trackID, amount: result.Data.cost})
        setIslogged(true)
        onClose();
      } 
     

    } catch (error) {
      console.error("Error fetching order details:", error);
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const storedData = localStorage.getItem('formData');
    if (storedData) {
      const data = JSON.parse(storedData);
      const trackingID = data.trackingID
      fetchOrder(trackingID)
    } 
}, []);
  // Keep checking order details and ensure modal stays open if details are incomplete
  useEffect(() => {
    if (Object.values(orderDetails).some(field => field === '')) {
      onOpen()
    }
  }, [orderDetails, onClose])

  // Function to update the order details after tracking ID submission
  const handleSubmit = async (trackID) => {
    setIsLoading(true)
    try {
      // Make an API request to the PHP backend with the tracking ID
      const response = await fetch('https://enky.xgramm.com/enky_backend/mains/verifyTrackID.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          trackID: trackID
        })
      });
      
      const result = await response.json();
      processToast(result.Status, result.Message)
      setIsLoading(false)
      if (result.Status === 'Success') {
        // Update order details with the fetched data
        setOrderDetails({
          trackID: trackID,
          service: result.Service || 'N/A',
          payment: result.Data.payment_type || 'N/A',
          time: result.Data.date || 'N/A',
          feedback: result.Data.feedback || 'No feedback available',
          status: result.Data.status || 'Unknown'
        });
        setModalData({...modalData, trackingID: trackID, amount: result.Data.cost})
        setIslogged(true)
        onClose();
      } 
     

    } catch (error) {
      console.error("Error fetching order details:", error);
      setIsLoading(false)
    }
  };
  

  const handleActions = () => {
    if (orderDetails.status == 'Success') {
      setModalShow(true)
    }else if(orderDetails.status == 'Pending'){
      window.location.replace(`https://media.xgramm.com/enky/preview.php?trackID=${orderDetails.trackID}`)
    }
  }
  
  const onPaymentSubmition = async(data) => {
    setIsModalLoading(true)
    try {
      const response = await fetch('https://enky.xgramm.com/enky_backend/mains/payment.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });
      const result = await response.json()
      processToast(result.Status, result.Message)
      setIsModalLoading(false)
      setTimeout(() => {
        handleSubmit(orderDetails.trackID)
      }, 20000)
    } catch (error) {
      console.error("Payment Error:", error)
      setIsModalLoading(false)
    }
  }
  return (
    <div className='main'>
      <div className="w3-container">
        <div className="w3-row w3-card w3-border w3-white w3-round w3-margin">
          <div className="w3-row secondary w3-padding order-top">
            <p className="w3-large w3-text-white bold margin-0">View Application</p>
          </div>
          <div className="w3-row">
            <div className="w3-panel w3-padding flex-row">
              <div className="w3-row w3-padding">
                <p className="w3-large margin-0 bold primary">Reference Number</p>
              </div>
              <div className="w3-row">
                <p className="w3-large margin-0">{orderDetails.trackID}</p>
              </div>
            </div>
            <div className="w3-panel w3-padding flex-row">
              <div className="w3-row w3-padding">
                <p className="w3-large margin-0 bold primary">Application Information</p>
              </div>
              <div className="w3-row">
                <p className="w3-large margin-0 w3-text-grey"><span className='bold w3-text-black'>Service:</span> {orderDetails.service}</p>
                <p className="w3-large margin-0 w3-text-grey"><span className='bold w3-text-black'>Payments Mode:</span> {orderDetails.payment}</p>
                <p className="w3-large margin-0 w3-text-grey"><span className='bold w3-text-black'>Time Of Submission:</span> {orderDetails.time}</p>
                <p className="w3-large margin-0 w3-text-grey"><span className='bold w3-text-black'>Feedback:</span> {orderDetails.feedback}</p>
              </div>
            </div>
            <div className="w3-panel w3-padding flex-row">
              <div className="w3-row w3-padding">
                <p className="w3-large margin-0 bold primary">Actions</p>
              </div>
              <div className="w3-row">
              <Button colorScheme='teal' variant='solid' isDisabled={orderDetails.status === 'Complete' || orderDetails.status === 'Onprocess' || orderDetails.status === ''} onClick={handleActions}>
                {(orderDetails.status == 'Incomplete') ?  'Complete Order' : (orderDetails.status == 'Onprocess') ? 'Onprocess...' : (orderDetails.status == 'Pending') ? 'Preview Here' : (orderDetails.status == 'Success') ? 'Pay Here' : 'Completed'}
              </Button>
              </div>
            </div>
            <div className="w3-panel w3-padding flex-row">
              <div className="w3-row w3-padding">
                <p className="w3-large margin-0 bold primary">Status</p>
              </div>
              <div className="w3-row">
                <Badge ml='1' fontSize='0.8em' variant='subtle' colorScheme={(orderDetails.status == 'Completed' || orderDetails.status == 'Success') ? 'green' : (orderDetails.status == 'Pending') ? 'yellow' : 'red'}>
                  {orderDetails.status}
                </Badge>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Login isOpen={isOpen} onClose={onClose} onSubmit={handleSubmit} isLoading={isLoading} isLogged={isLogged}/>
      <Payment  isLoading={isModalLoading} setIsLoading={setIsModalLoading} modalShow={modalShow} setModalShow={setModalShow} onSubmit={onPaymentSubmition} modalData={modalData} setModalData={setModalData} />
    </div>
  )
}

export default Order
