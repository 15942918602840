import { Menu, MenuButton, MenuList,MenuItem,Button, Icon, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, DrawerFooter, useDisclosure } from '@chakra-ui/react'
import React, { useRef } from 'react'
import { Link, NavLink } from 'react-router-dom'

const Topbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()
  return (
    <div style={{height: '9vh'}} className='w3-container w3-top w3-card w3-white navs'>
      <div style={{width: '100%'}} className="w3-row padding-sm">
        <div className="w3-left">
          <Link to='/' className='w3-xlarge margin-0 primary bold text-dec-none'>Enky<span className='text-success'>Solutions</span></Link>
        </div>
        
        <div className="w3-right flex-row w3-hide-small">
          <div style={{marginRight: '50px'}} className="flex-row bold">
              <NavLink to='/' className='text-dec-none nav-a w3-padding'>Home</NavLink>
              <NavLink to='/service' className='text-dec-none nav-a w3-padding'>Services</NavLink>
              <NavLink to='/about' className='text-dec-none nav-a w3-padding'>About</NavLink>
              {/* <NavLink to='/blog' className='text-dec-none nav-a w3-padding'>Blog</NavLink> */}
              <NavLink to='/contact' className='text-dec-none nav-a w3-padding'>Contact</NavLink>
              <NavLink to='/faq' className='text-dec-none nav-a w3-padding'>FAQ</NavLink>
          </div>
          <div className='flex-row'>
            <Link to='/order' className='text-dec-none w3-text-white w3-padding btn btn-primary margin-sm'>Track Order</Link>
          </div>
        </div>
        <div className="w3-right flex-col w3-hide-large">
        <Menu>
          <MenuButton as={Button} colorScheme='purple' onClick={onOpen}>
          <i className="fa-solid fa-bars"></i>
          </MenuButton>
          
          <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Menu</DrawerHeader>
              <DrawerBody>
              <div style={{marginRight: '50px'}} className="flex-col bold">
                <NavLink to='/' className='text-dec-none nav-a w3-padding'>Home</NavLink>
                <NavLink to='/service' className='text-dec-none nav-a w3-padding'>Services</NavLink>
                <NavLink to='/about' className='text-dec-none nav-a w3-padding'>About</NavLink>
                {/* <NavLink to='/blog' className='text-dec-none nav-a w3-padding'>Blog</NavLink> */}
                <NavLink to='/contact' className='text-dec-none nav-a w3-padding'>Contact</NavLink>
                <NavLink to='/faq' className='text-dec-none nav-a w3-padding'>FAQ</NavLink>
                <div className='flex-row'>
                  <Link to='/order' className='text-dec-none w3-text-white w3-padding btn btn-primary margin-sm'>Track Order</Link>
                </div>
            </div>
              </DrawerBody>
              <DrawerFooter>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </Menu>
        </div>
      </div>
    </div>
  )
}

export default Topbar