import { Box, Skeleton, SkeletonText } from '@chakra-ui/react'
import React from 'react'

export const SkeletonFeatured = () => {
  return (
    <div className="w3-col l4 m4 s12 w3-padding">
            <Box padding='6' boxShadow='lg'>
                <Skeleton size='10' height='50px' width='20' />
                <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
            </Box>
      
    </div>

  )
}
export const SkeletonProducts = () => {
    return (
        <div className="w3-col l4 m4 s12 w3-padding">
            <Box padding='6' boxShadow='lg'>
                <Skeleton size='10' height='50px' width='20' />
                <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
            </Box>
        </div>
  
    )
  }
