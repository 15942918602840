import React, { useEffect, useRef } from 'react'
import Hero from '../components/hero'
import about from '../images/about-img1.png'
import LoadingBar from 'react-top-loading-bar'
import { Card, CardBody, Heading, Stack, Image, Text } from '@chakra-ui/react'
import bazarin from '../images/bazarin.jpeg'
import enky from '../images/bitech.png'

const About = (props) => {
    useEffect(() => {
        props.setIsNavShow(true)
      }, [])
      const ref = useRef(null);

  const startLoading = () => {
    ref.current.continuousStart();
  };

  const stopLoading = () => {
    ref.current.complete();
  };
  return (
    <div className='main'>
        <LoadingBar color="#f11946" ref={ref} />
        <Hero image={about} name="Is Good To Know Who We Are!" description='We are a tech startup offering digital solutions country wide with our talented and dedicated team.'/>
        <div className="w3-container w3-white padding-sm">
            <div className="w3-row text-center w3-padding">
              <p className='bold primary margin-0'>OUR TEAM</p>
              <p className='w3-xlarge bold margin-0'>Here Is Our Business <br/>Runners</p>
            </div>
            <div className="w3-row w3-padding-24">
                <div className="w3-col l6 m6 s12 grid-center padding-sm">
                    <Card maxW='sm' bg="rgb(229, 230, 231)">
                        <CardBody>
                            <Image
                            src={bazarin}
                            alt='Green double couch with wooden legs'
                            borderRadius='lg'
                            boxSize="50%"
                            />
                            <Stack mt='6' spacing='3'>
                            <Heading size='md'>Bazarin Ngigi</Heading>
                            <Text>
                            I believe in a future where innovation meets customer needs. At Enky Solution, we’re committed to pushing boundaries and creating solutions that not only inspire but also empower our clients.
                            </Text>
                            <Text color='blue.600' fontSize='1xl'>
                                CEO AND FOUNDER
                            </Text>
                            </Stack>
                        </CardBody>
                    </Card>
                </div>
                <div className="w3-col l6 m6 s12 grid-center padding-sm">
                    <Card maxW='sm' bg="rgb(229, 230, 231)">
                        <CardBody>
                            <Image
                            src={enky}
                            alt='Green double couch with wooden legs'
                            borderRadius='lg'
                            boxSize="50%"
                            />
                            <Stack mt='6' spacing='3'>
                            <Heading size='md'>Grieze Kevine</Heading>
                            <Text>
                            Building relationships is at the heart of what we do. I’m passionate about exploring new opportunities and ensuring our partners succeed alongside us. Together, we can achieve remarkable growth and impact
                            </Text>
                            <Text color='blue.600' fontSize='1xl'>
                                Business Development And HELB Consultant
                            </Text>
                            </Stack>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About