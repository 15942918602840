import React from 'react'

const Featured = (props) => {
  return (
    <div className="w3-row w3-third w3-padding">
        <div style={{backgroundColor: 'rgb(245, 246, 247)'}} className='w3-panel  w3-round-large w3-hover-card-4'>
            <div className='w3-row grid-center'>
                <img src={props.image} width={130}/>
            </div>
            <div className='w3-row text-center'>
                <p className='w3-large bold margin-0'>{props.name}</p>
                <p className='w3-large w3-text-grey'>{props.description}</p>
            </div>
            <div className='w3-row grid-center'>
                <button className='btn secondary w3-text-white w3-margin bold w3-hover-blue' onClick={() => props.handleBtn(props.productID)}>Request Now</button>
            </div>
        </div>
    </div>

  )
}

export default Featured