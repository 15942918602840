import React, { useEffect, useState } from 'react'
import service from '../images/why-choose-img1.png'
import Hero from '../components/hero'
import Products from '../components/products'
import academic from '../images/academic.png'
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, useDisclosure } from '@chakra-ui/react'
import Checkout from '../components/checkout'
import { SkeletonProducts } from '../components/skeleton'

const Services = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [products, setProducts] = useState();
  const [forms, setForms] = useState();
  const [isLoading, setIsloading] = useState(true)
  const[formData, setFormData] = useState({
    name: "",
    description: "",
    inputs: [],
    productID: '',
    banner: '',
    cost: ''
  })

    useEffect(() => {
        props.setIsNavShow(true)
      }, [])
      // const handleBtn = () => {
      //   setIOpen(true)
      // }

      useEffect(() => {
        const fetchProducts = async () => {
          try {
            const response = await fetch('https://enky.xgramm.com//enky_backend/mains/products.php');
            const results = await response.json();
            if (results.Status == 'Success') {
              setProducts(results.products);
              setForms(results.forms)
              setTimeout(() => {
                setIsloading(false)
              }, 5000)
            }
          } catch (error) {
            console.error("Fetching Products error:", error);
          }
        }
        fetchProducts()
      }, [])
      const handleOrderBtn = (id) => {
        const filteredInputs = forms.filter(form => form.productID === id); // Get the form inputs for the product
        const filteredProducts = products.filter(product => product.ID === id); // Get the specific product
      
        if (filteredProducts.length > 0) {
          // Update the formData with product details and inputs
          setFormData({
            ...formData,
            name: filteredProducts[0].name,
            description: filteredProducts[0].description,
            inputs: filteredInputs[0].form_schema, // set the form inputs here
            productID: filteredProducts[0].ID,
            cost: filteredProducts[0].cost,
            banner: filteredProducts[0].banner
          });
          
          // Open the modal after formData has been set
          onOpen();
        }
      };
      
  return (
    <div className='main'>
        <Hero image={service} name="All Our Professional And Creative Services!" description='We have come up With Automated Service Feature the one that allow you to select an order and it will automate you request.'/>
        <div className="w3-container w3-white">
         <div className="w3-panel grid-center">
            <div className="w3-row text-center">
              <p className='bold primary margin-0'>SERVICES</p>
              <p className='w3-xlarge bold margin-0'>Select The service And  Click <br/> Order To Automate <br/>Your Request</p>
            </div>
          </div>
          {!isLoading && 
          <div className="w3-panel">
          {products && products.map((product) => {
            
            return(
              <Products key={product.ID} name={product.name} description={product.description} image={`https://media.xgramm.com/enky/enkyproducts/${product.banner}`} handleBtn={handleOrderBtn} productID={product.ID}/>
            )
          })}
          
        </div>
          }
          
          {isLoading && <div className='w3-panel'>
              <SkeletonProducts />
              <SkeletonProducts />
              <SkeletonProducts />
              <SkeletonProducts />
              <SkeletonProducts />
              <SkeletonProducts />
              </div>}
        </div>
        <Checkout isOpen={isOpen} onOpen={onOpen} onClose={onClose} formData={formData} />
    </div>
  )
}

export default Services