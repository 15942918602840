import React, { useCallback, useEffect, useState } from 'react';
import { Box, Text, Image, VStack, Button } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';

const FileUpload = ({ onFileSelect }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]; // Assuming we want only the first file

    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setSelectedFile({ name: file.name, url: fileUrl, file });
      onFileSelect(file); // Pass the file back to the parent component
    }
  }, [onFileSelect]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleClear = () => {
    if (selectedFile) {
      URL.revokeObjectURL(selectedFile.url); // Cleanup the object URL
    }
    setSelectedFile(null);
    onFileSelect(null); // Notify the parent component
  };

  useEffect(() => {
    return () => {
      if (selectedFile) {
        URL.revokeObjectURL(selectedFile.url);
      }
    };
  }, [selectedFile]);

  return (
    <VStack spacing={4} align="stretch">
      <Box
        {...getRootProps()}
        border="2px dashed gray"
        borderRadius="md"
        padding={4}
        textAlign="center"
        cursor="pointer"
        _hover={{ borderColor: 'blue.500' }}
      >
        <input {...getInputProps()} />
        <Text fontSize="lg" color="gray.600">
          Drag & drop a file here, or click to select a file
        </Text>
      </Box>

      {selectedFile && (
        <Box
          borderWidth={1}
          borderRadius="md"
          padding={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderColor="blue.500"
        >
          <Text>{selectedFile.name}</Text>
          <Image
            src={selectedFile.url}
            alt={selectedFile.name}
            boxSize="50px"
            objectFit="cover"
            borderRadius="md"
          />
          <Button onClick={handleClear}>Clear</Button> {/* Clear button */}
        </Box>
      )}
    </VStack>
  );
};

export default FileUpload;
