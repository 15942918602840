import React, { useEffect, useState } from 'react'

const ScrollAnimation = (props) => {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
      const offset = window.scrollY;
      // Adjust the threshold as needed
      if (offset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
  return (
    <div className={`items-container ${isVisible ? 'animate' : ''}`}>
      {props.children}
      {/* Add more items as needed */}
    </div>
  )
}

export default ScrollAnimation