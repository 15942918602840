import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/home';
import { useEffect, useRef, useState } from 'react';
import Topbar from './components/topbar';
import Footer from './components/footer';
import Services from './pages/service';
import Order from './pages/order';
import About from './pages/about';
import LoadingBar from 'react-top-loading-bar';
import ScrollAnimation from './components/ScrollAnimation';
import Contact from './pages/contact';
import Faq from './pages/faq';
import Information from './components/information';
import { FaWhatsapp } from 'react-icons/fa';
import { Button, Icon } from '@chakra-ui/react'; 

const WhatsAppButton = () => {
  const handleWhatsAppClick = () => {
    // Replace the phone number with the desired one
    const phoneNumber = '254769472724'; // Update with your phone number
    const message = 'Hello, I need helps?';
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    window.open(url, '_blank'); // Opens WhatsApp in a new tab
  };
  return (
    <Button
      position="fixed"
      bottom="20px"
      right="20px"
      colorScheme="whatsapp"
      onClick={handleWhatsAppClick}
      borderRadius="full"
      boxShadow="md"
      size="lg"
      zIndex="999"
    >
      <Icon as={FaWhatsapp} w={6} h={6} />
    </Button>
  );
 
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to the top when the route changes
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const LoadingBarHandler = () => {
  const ref = useRef(null);
  const location = useLocation();
// Scroll to Top Component


  useEffect(() => {
    // Start the loading bar when the route changes
    ref.current.continuousStart();

    // Simulate loading time (or wait for async data fetching)
    setTimeout(() => {
      ref.current.complete();
    }, 1000); // Adjust the delay as necessary

  }, [location]); // This runs every time the route changes

  return <LoadingBar color="rgb(85, 26, 245)" height={4}  ref={ref} />;
};
function App() {
  const [isNavShow, setIsNavShow] = useState(true)
  return (
    <BrowserRouter>
    <LoadingBarHandler />
    <ScrollToTop />
    <Topbar/>
    <ScrollAnimation>
      <Routes>
        <Route path='/' element={<Home isNavShow={isNavShow} setIsNavShow={setIsNavShow} />} />
        <Route path='/service' element={<Services isNavShow={isNavShow} setIsNavShow={setIsNavShow} />} />
        <Route path='/order' element={<Order isNavShow={isNavShow} setIsNavShow={setIsNavShow} />} />
        <Route path='/about' element={<About isNavShow={isNavShow} setIsNavShow={setIsNavShow} />} />
        <Route path='/contact' element={<Contact isNavShow={isNavShow} setIsNavShow={setIsNavShow} />} />
        <Route path='/faq' element={<Faq isNavShow={isNavShow} setIsNavShow={setIsNavShow} />} />
        <Route path='/information' element={<Information isNavShow={isNavShow} setIsNavShow={setIsNavShow} />} />
      </Routes>
    </ScrollAnimation>
    <Footer />
    <WhatsAppButton />
    </BrowserRouter>
  );
}

export default App;
