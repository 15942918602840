import React, { useEffect, useState } from 'react'
import mainImage from '../images/main-pic.png'
import Featured from '../components/featured'
import academic from '../images/academic.png'
import cv from '../images/cv.png'
import website from '../images/Website.png'
import feature1 from '../images/feature-image1.png'
import feature2 from '../images/feature-image2.png'
import feature3 from '../images/Mobile Security.png'
import { useDisclosure } from '@chakra-ui/react'
import Checkout from '../components/checkout'
import { useNavigate } from 'react-router-dom'
import { SkeletonFeatured, SkeletonProducts } from '../components/skeleton'
import Footer from '../components/footer'

const Home = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  useEffect(() => {
    props.setIsNavShow(true)
  }, [])
  const navigate = useNavigate()
  const [isLoading, setIsloading] = useState(true)
  const[formData, setFormData] = useState({
    name: "",
    description: "",
    inputs: [],
    productID: 0,
    banner: '',
    cost: ''
  })
  const [products, setProducts] = useState();
  const [forms, setForms] = useState();
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://enky.xgramm.com//enky_backend/mains/featured.php');
        const results = await response.json();
        if (results.Status == 'Success') {
          setProducts(results.products);
          setForms(results.forms)
          setTimeout(() => {
            setIsloading(false)
          }, 2000)
        }
      } catch (error) {
        console.error("Fetching Products error:", error);
      }
    }
    fetchProducts()
  }, [])
  const handleOrderBtn = (id) => {
    const filteredInputs = forms.filter(form => form.productID === id); // Get the form inputs for the product
    const filteredProducts = products.filter(product => product.ID === id); // Get the specific product
  
    if (filteredProducts.length > 0) {
      // Update the formData with product details and inputs
      setFormData({
        ...formData,
        name: filteredProducts[0].name,
        description: filteredProducts[0].description,
        inputs: filteredInputs[0].form_schema, // set the form inputs here
        productID: filteredProducts[0].ID,
        cost: filteredProducts[0].cost,
        banner: filteredProducts[0].banner
      });
      
      // Open the modal after formData has been set
      onOpen();
    }
  };
  return (
    <div className='main'>
        <div className="w3-container">
          <div className='w3-panel'>
            <div className='w3-half'>
              <div className='w3-row'>
                <p className='primary w3-large bold'>Enky Brand, promoted</p>
                <p className='w3-xxxlarge margin-0 bold'>Creative solutions <br/> to improve <br/> your business!</p>
                <p className='w3-text-grey bold w3-medium margin-0'>We work hand-in-hand with industry-leading brands to help <br/> redefine the possibilities and potential of digital engagements.</p>
                <button onClick={() => navigate('/service')} className="btn secondary w3-text-white w3-hover-blue w3-hover-text-white w3-margin">ALL OUR SERVICES</button>
              </div>
            </div>
            <div className='w3-half grid center'>
              <img src={mainImage} width={400} />
            </div>
          </div>
        </div>
        <div className='w3-container w3-white grid-center w3-padding'>
          <div className="w3-panel text-center">
              <p className='w3-medium primary bold margin-0'>OUR SERVICES</p>
              <p className="w3-xxlarge bold margin-0">Our Featured Services</p>
              <p className='w3-text-grey bold w3-medium margin-0'>Real innovations and positive customer experience are the heart of successful <br/> communication. No fake products and services.</p>
          </div>
        </div>
        <div className='w3-container w3-white grid-center'>
          {!isLoading && 
          <div className='w3-panel'>
          {products && products.map((product) => {
            
            return(
              <Featured key={product.ID} name={product.name} description={product.description} image={`https://media.xgramm.com/enky/enkyproducts/${product.banner}`} handleBtn={handleOrderBtn} productID={product.ID}/>
            )
          })}
          </div>
          }
            
            {isLoading && <div className='w3-panel'>
              <SkeletonFeatured />
              <SkeletonFeatured />
              <SkeletonFeatured />
              </div>}
        </div>
        <div className='w3-container w3-white'>
          <div className="w3-panel text-center">
                <p className='w3-medium primary bold margin-0'>OUR SERVICES</p>
                <p className="w3-xxlarge bold margin-0">Amazing Business Solution <br /> Whatever Your Needs</p>
                <p className='w3-text-grey bold w3-medium margin-0'>Real innovations and positive customer experience are the heart of successful <br/> communication. No fake products and services.</p>
            </div>
        </div>
        <div className='w3-container w3-white'>
          <div className='w3-panel'>
            <div className="w3-half">
              <p className='w3-medium bold primary margin-0'>DIGITAL MARKETING</p>
              <p className='w3-xxlarge bold margin-0'>Creative solutions, creative <br/> results</p>
              <p className='w3-text-grey w3-large margin-0'>We believe brand interaction is key to communication. Real innovations and positive customer experience are the heart of successful communication.</p>
              <div className='w3-row flex-row'>
                <p className='w3-xlarge primary margin-0 bold'><i class="fa-solid fa-check"></i></p>
                <p className='w3-large margin-0 w3-padding bold'>Core Development</p>
              </div>
              <p className='w3-text-grey w3-large margin-0'>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
            </div>
            <div className='w3-half grid-center'>
              <img src={feature1} alt="" width={400} />
            </div>
          </div>
          <div className='w3-panel'>
            <div className='w3-half grid-center w3-hide-small'>
              <img src={feature2} alt="" width={400} />
            </div>
            <div className="w3-half">
              <p className='w3-medium bold primary margin-0'>DIGITAL TRANSFORMATION</p>
              <p className='w3-xxlarge bold margin-0'>Empowering Your Digital <br /> Transformation</p>
              <p className='w3-text-grey w3-large margin-0'>We'll work with you to understand your business goals and develop a comprehensive digital strategy. Our skilled team of developers will bring your digital vision to life, leveraging the latest technologies.</p>
              <div className='w3-row flex-row'>
                <p className='w3-xlarge primary margin-0 bold'><i class="fa-solid fa-check"></i></p>
                <p className='w3-large margin-0 w3-padding bold'>Optimization</p>
              </div>
              <p className='w3-text-grey w3-large margin-0'>We'll continuously monitor and optimize your digital solutions to ensure maximum efficiency and performance.</p>
            </div>
            <div className='w3-half grid-center w3-hide-large'>
              <img src={feature2} alt="" width={400} />
            </div>
          </div>
          <div className='w3-panel'>
            <div className="w3-half">
              <p className='w3-medium bold primary margin-0'>SECURED SERVICES</p>
              <p className='w3-xxlarge bold margin-0'>Secure and Reliable <br/> Solutions</p>
              <p className='w3-text-grey w3-large margin-0'>We prioritize the security of your sensitive information, implementing robust measures to safeguard your data. Our team stays up-to-date with the latest regulations, ensuring your solutions meet all necessary compliance standards.</p>
              <div className='w3-row flex-row'>
                <p className='w3-xlarge primary margin-0 bold'><i class="fa-solid fa-check"></i></p>
                <p className='w3-large margin-0 w3-padding bold'>Continuous Monitoring</p>
              </div>
              <p className='w3-text-grey w3-large margin-0'>We continuously monitor your systems and networks, promptly  addressing any potential vulnerabilities or threats.</p>
            </div>
            <div className='w3-half grid-center'>
              <img src={feature3} alt="" width={400} />
            </div>
          </div>
        </div>
        <div className="w3-container">
          <div className="w3-panel grid-center">
            <div className="w3-row text-center">
              <p className='bold primary margin-0'>Work Process</p>
              <p className='w3-xlarge bold margin-0'>How do we work ?</p>
            </div>
          </div>
          <div className="w3-panel">
            <div className="w3-third w3-padding">
              <div className="w3-panel w3-white w3-round-large w3-card">
                  <div className="w3-row grid-center w3-padding margin-sm">
                    <div style={{width: '75px', height: '75px'}} className="grid-center margin-0 blur-primary primary bold w3-round-large"> <p className='w3-xxlarge margin-0'> <i class="fa-solid fa-book-atlas"></i></p></div>
                  </div>
                  <div className='w3-row grid-center text-center'>
                    <p className="w3-large bold margin-0">Project Plan</p>
                    <p className='w3-large w3-text-grey'>We make a detailed documentation about the project and how we are going to proceed on the project.</p>
                  </div>
              </div>
            </div>
            <div className="w3-third w3-padding">
              <div className="w3-panel w3-white w3-round-large w3-card">
                  <div className="w3-row grid-center w3-padding margin-sm">
                    <div style={{width: '75px', height: '75px'}} className="grid-center margin-0 blur-primary primary bold w3-round-large"> <p className='w3-xxlarge margin-0'><i class="fa-regular fa-image"></i></p></div>
                  </div>
                  <div className='w3-row grid-center text-center'>
                    <p className="w3-large bold margin-0">
                    Technical Design</p>
                    <p className='w3-large w3-text-grey'>Our senior programmers designs the total code structure for the application.</p>
                  </div>
              </div>
            </div>
            <div className="w3-third w3-padding">
              <div className="w3-panel w3-white w3-round-large w3-card">
                  <div className="w3-row grid-center w3-padding margin-sm">
                    <div style={{width: '75px', height: '75px'}} className="grid-center margin-0 blur-primary primary bold w3-round-large"> <p className='w3-xxlarge margin-0'><i class="fa-solid fa-microscope"></i></p></div>
                  </div>
                  <div className='w3-row grid-center text-center'>
                    <p className="w3-large bold margin-0">Validation & Testing</p>
                    <p className='w3-large w3-text-grey'>After the team has tested the application, our project manager will coordinate with the client to cross verify the code for bugs. After receiving the acceptance from the client.</p>
                  </div>
              </div>
            </div>
          </div>
          <div className="w3-panel w3-white w3-round-xlarge w3-card">
            <div className="w3-third grid-center">
              <div className="w3-row text-center">
                <p className="w3-xxlarge margin-0 primary bold">100<sup>+</sup></p>
                <p className='bold w3-text-grey'>Projects Done</p>
              </div>
            </div>
            <div className="w3-third grid-center">
              <div className="w3-row text-center">
                <p className="w3-xxlarge margin-0 primary bold">50<sup>+</sup></p>
                <p className='bold w3-text-grey'>Satisfied Clients</p>
              </div>
            </div>
            <div className="w3-third grid-center">
              <div className="w3-row text-center">
                <p className="w3-xxlarge margin-0 primary bold">100%</p>
                <p className='bold w3-text-grey'>Success Rate</p>
              </div>
            </div>
          </div>
        </div>
        <div className="w3-container w3-white grid-center">
          <div className="w3-panel">
              <div className="w3-row w3-margin text-center w3-padding">
                <p className="w3-xlarge bold">Get Started Now</p>
                <p className='w3-large w3-text-grey margin-0'>Request an order or get in touch with us.</p>
              </div>
              <div className="w3-row flex-row j-center">
                <button onClick={() => navigate('/service')} className='btn w3-large secondary w3-hover-blue margin-sm w3-text-white w3-hover-text-white'>Get Started</button>
                <button onClick={() => navigate('/contact')} className='btn w3-large blur-primary primary w3-hover-blue w3-hover-text-white bold'>Request a Demo</button>
              </div>
          </div>
        </div>
        <Checkout isOpen={isOpen} onOpen={onOpen} onClose={onClose} formData={formData} />
    </div>
  )
}

export default Home