import { Box, Button, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FileUpload from './FileUpload';

const TextInput = ({ name, placeholder, value, onChange, title }) => (
    <div className="w3-panel">
        <p className='margin-0'>{title}<span className='w3-text-red'>*</span></p>
        <input
            style={{ width: '100%' }}
            type="text"
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            className='w3-border w3-round w3-border-grey w3-padding outline-none'
            required
        />
    </div>
);

const EmailInput = ({ name, placeholder, value, onChange, title }) => (
    <div className="w3-panel">
        <p className='margin-0'>{title}<span className='w3-text-red'>*</span></p>
        <input
            style={{ width: '100%' }}
            type="email"
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            className='w3-border w3-round w3-border-grey w3-padding outline-none'
            required
        />
    </div>
);

const NumberInput = ({ name, placeholder, value, onChange, title }) => (
    <div className="w3-panel">
        <p className='margin-0'>{title}<span className='w3-text-red'>*</span></p>
        <input
            style={{ width: '100%' }}
            type="number"
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            className='w3-border w3-round w3-border-grey w3-padding outline-none'
            required
        />
    </div>
);

const TelInput = ({ name, placeholder, value, onChange, title }) => (
    <div className="w3-panel">
        <p className='margin-0'>{title}<span className='w3-text-red'>*</span></p>
        <input
            style={{ width: '100%' }}
            type="tel"
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            className='w3-border w3-round w3-border-grey w3-padding outline-none'
            required
        />
    </div>
);

const Information = (props) => {
    const [formData, setFormData] = useState({ inputs: {} });
    const [formValues, setFormValues] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const toast = useToast();
    
    const processToast = (status, msg) => {
        const examplePromise = new Promise((resolve, reject) => {
            setTimeout(() => {
                if (status === "success") {
                    resolve(200);
                } else {
                    reject(200);
                }
            }, 2000);
        });

        toast.promise(examplePromise, {
            success: { title: 'Success', description: msg },
            error: { title: 'An Error Occurred', description: msg },
            loading: { title: 'Inserting Your Data', description: 'Please wait' },
        });
    };

    useEffect(() => {
        props.setIsNavShow(true);
    }, []);

    useEffect(() => {
        const storedData = localStorage.getItem('formData');
        if (storedData) {
            const data = JSON.parse(storedData);
            setFormData(data);
            const initialValues = Object.fromEntries(Object.entries(data.inputs).map(([key]) => [key, '']));
            setFormValues(initialValues);
        } else {
            navigate('/service');
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleFileSelect = (file) => {
        setFormValues((prevValues) => ({
            ...prevValues,
             file,  // Change the key here to match the backend expectation
        }));
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);


        const formDataToSend = new FormData();

        // Add all text inputs to FormData
        Object.keys(formValues).forEach((key) => {
            if (key !== 'file') {
                formDataToSend.append(key, formValues[key]);
            }
        });

        // Add the trackingID to FormData
        const storedData = localStorage.getItem('formData');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            formDataToSend.append('trackingID', parsedData.trackingID);
        }

        // Send formDataToSend to your PHP backend using fetch
        try {
            const response = await fetch('https://enky.xgramm.com/enky_backend/mains/information.php', {
                method: 'POST',
                body: formDataToSend,
            });

            const result = await response.json();
            processToast(result.status, result.message);
            setIsLoading(false);
            

            if (result.status == 'success') {
                setTimeout(() => {
                    navigate('/order');
                }, 5000);
            }
            if (response.ok) {
                console.log('Success:', result);
            } else {
                console.error('Error:', result.message);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setIsLoading(false);
        }
    };

    return (
        <div className='main w3-padding'>
            <form onSubmit={handleSubmit}>
                <div className="w3-row w3-white w3-round-large">
                    <div className="w3-panel">
                        <div className="w3-row">
                            <p className="w3-text-grey bold margin-0">Information Details</p>
                            {Object.entries(formData.inputs).map(([key, value]) => {
                                switch (value.type) {
                                    case 'text':
                                        return <TextInput key={key} title={value.title} placeholder={value.placeholder} name={value.name} value={formValues[key] || ''} onChange={handleChange} />;
                                    case 'email':
                                        return <EmailInput key={key} title={value.title} placeholder={value.placeholder} name={value.name} value={formValues[key] || ''} onChange={handleChange} />;
                                    case 'number':
                                        return <NumberInput key={key} title={value.title} placeholder={value.placeholder} name={value.name} value={formValues[key] || ''} onChange={handleChange} />;
                                    case 'file':
                                        return <FileUpload key={key} title={value.title} onFileSelect={handleFileSelect} />;
                                    case 'tel':
                                        return <TelInput key={key} title={value.title} placeholder={value.placeholder} name={value.name} value={formValues[key] || ''} onChange={handleChange} />;
                                    default:
                                        return null;
                                }
                            })}
                            <div className="w3-panel grid-center">
                                {!isLoading && <Button colorScheme='blue' size='lg' type='submit'>Submit</Button>}
                                {isLoading && <Button colorScheme='blue' size='lg' isDisabled>Please wait...</Button>}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Information;
