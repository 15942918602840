import React, { useEffect } from 'react'
import faq from '../images/academic.png'
import Hero from '../components/hero'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from '@chakra-ui/react'

const Faq = (props) => {
  useEffect(() => {
    props.setIsNavShow(true)
  }, [])
  return (
    <div className='main'>
        <Hero image={faq} name="Find Out Frequently Asked Quizes!" description='If you cannot find your concern feel free to contact us. We are always ready to come up with solution to suite your needs!'/>
        <div className="w3-container w3-padding w3-white">
          <div className="w3-row text-center w3-padding w3-margin">
              <p className='bold primary margin-0'>FAQs</p>
              <p className='w3-xlarge bold margin-0'>Explore Our Diffrent Approach <br/> Of Various Challenges</p>
            </div>
            <Accordion allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton _expanded={{ bg: 'rgb(50, 98, 230)', color: 'white' }}>
                    <Box as='span' flex='1' textAlign='left' fontSize='x-large'>
                      How Do I know If My Order Is Submitted ?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} fontSize='large'>
                  To know whether your order was submitted. You will recieve a tracking ID and a link both in sms and email that Directs you to order section where you copy the ID and see the current status of your order.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton _expanded={{ bg: 'rgb(50, 98, 230)', color: 'white' }}>
                    <Box as='span' flex='1' textAlign='left' fontSize='x-large'>
                      Is it must I pay in order my to be submitted ?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} fontSize='large'>
                  The obvious answer to this is no. In Enky we have an option of user to pay after the order is completed or pay instantly depending on your feeling.
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton _expanded={{ bg: 'rgb(50, 98, 230)', color: 'white' }}>
                    <Box as='span' flex='1' textAlign='left' fontSize='x-large'>
                      How long does it take to complete an order ?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} fontSize='large'>
                  Depends on the service ordered. Some like academic research, CV preparation and other cyber services may take less than 2 hours to complete whereas website development it may take upto two weeks.
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton _expanded={{ bg: 'rgb(50, 98, 230)', color: 'white' }}>
                    <Box as='span' flex='1' textAlign='left' fontSize='x-large'>
                      How do I make payments ?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} fontSize='large'>
                  We have Intergreted an automated mpesa payments where after satisfactory of the service you will be redirected to a page to make payments and will require to enter mpesa pin to the stk push and automatically you will recieve your ordered service
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
        </div>
    </div>
  )
}

export default Faq