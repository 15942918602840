import React, { useEffect } from 'react'
import Hero from '../components/hero'
import contact from '../images/why-choose-img1.png'

const Contact = (props) => {
    useEffect(() => {
        props.setIsNavShow(true)
      }, [])
  return (
    <div className='main'>
        <Hero image={contact} name="Ahh, So Far So Good Connect with Us!" description='Your voice is important to us. Whether you’re a customer, partner, or just curious, we’re eager to hear from you!'/>
        <div className="w3-container w3-white w3-padding">
              <div className="w3-panel">
                <p className="w3-xlarge margin-0 bold">General Inquiry</p>
                <p className='w3-large w3-text-grey margin-0'>support@enkysolutions.co.ke, hello@enkysolutions.co.ke</p>
                <p className='w3-large w3-text-grey margin-0'>+254 769 472 724 / +254 794 648 630</p>
              </div>
              <div className="w3-panel">
                <p className="w3-xlarge margin-0 bold">Sales</p>
                <p className='w3-large w3-text-grey margin-0'>sales@enkysolutions.co.ke, bitech@enkysolutions.co.ke</p>
                <p className='w3-large w3-text-grey margin-0'>+254 769 472 724 / +254 794 648 630</p>
              </div>
              <div className="w3-panel">
                <p className="w3-xlarge margin-0 bold">Demo Request</p>
                <p className='w3-large w3-text-grey margin-0'>Book a 10 minutes explanation of how to apply for services</p>
                <p className='w3-large w3-text-blue margin-0'>https://ankysolutions.co.ke/demo</p>
              </div>
            </div>
    </div>
  )
}

export default Contact