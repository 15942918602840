import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='w3-container footer padding-0'>
        <div className="w3-row">
          <div className="w3-panel w3-third">
            <Link style={{marginBottom: '10px'}} to='/' className='w3-xlarge primary bold text-dec-none'>Enky<span className='text-success'>Solutions</span></Link>
            <div style={{marginTop: '10px'}} className="w3-row">
              <p className='w3-text-white bold margin-0 w3-large'>Company Address:</p>
              <p className='w3-text-grey bold w3-large'>Nairobi, Kahawa west <br />Bima Road, Near Full Gospel Church.</p>
            </div>
            <div style={{marginTop: '10px'}} className="w3-row">
              <p className='w3-text-white bold margin-0 w3-large'>Email:</p>
              <p className='w3-text-grey bold w3-large w3-hover-text-blue'>support@enkysolutions.co.ke</p>
            </div>
            <div style={{marginTop: '10px'}} className="w3-row">
              <p className='w3-text-white bold margin-0 w3-large'>Contact:</p>
              <p className='w3-text-grey bold w3-large w3-hover-text-blue'> +254 791 156 520</p>
            </div>
          </div>
          <div className="w3-panel w3-third min-foot">
            <div className="w3-row">
              <p className="w3-xlarge w3-text-white bold">Company</p>
            </div>
            <div className="w3-row">
              <Link to='/about' className='text-dec-none w3-text-grey flex-row bold w3-hover-text-blue'>
                  <span className='w3-large margin-0 w3-padding'><i class="fa-solid fa-angle-right"></i></span>
                  <span className='w3-large bold margin-0'>About Us</span>
              </Link>
              <Link to='/service' className='text-dec-none w3-text-grey flex-row bold w3-hover-text-blue'>
                  <span className='w3-large margin-0 w3-padding'><i class="fa-solid fa-angle-right"></i></span>
                  <span className='w3-large bold margin-0'>Services</span>
              </Link>
              <Link to='/contact' className='text-dec-none w3-text-grey flex-row bold w3-hover-text-blue'>
                  <span className='w3-large margin-0 w3-padding'><i class="fa-solid fa-angle-right"></i></span>
                  <span className='w3-large bold margin-0'>Contact</span>
              </Link>
              <Link to='/service' className='text-dec-none w3-text-grey flex-row bold w3-hover-text-blue'>
                  <span className='w3-large margin-0 w3-padding'><i class="fa-solid fa-angle-right"></i></span>
                  <span className='w3-large bold margin-0'>Projects</span>
              </Link>
              <Link to='/about' className='text-dec-none w3-text-grey flex-row bold w3-hover-text-blue'>
                  <span className='w3-large margin-0 w3-padding'><i class="fa-solid fa-angle-right"></i></span>
                  <span className='w3-large bold margin-0'>Careers</span>
              </Link>
            </div>
          </div>
          <div className="w3-panel w3-third min-foot">
            <div className="w3-row">
              <p className="w3-xlarge w3-text-white bold">Services</p>
            </div>
            <div className="w3-row">
              <Link to='/service' className='text-dec-none w3-text-grey flex-row bold w3-hover-text-blue'>
                  <span className='w3-large margin-0 w3-padding'><i class="fa-solid fa-angle-right"></i></span>
                  <span className='w3-large bold margin-0'>Acardemic Research</span>
              </Link>
              <Link to='/service' className='text-dec-none w3-text-grey flex-row bold w3-hover-text-blue'>
                  <span className='w3-large margin-0 w3-padding'><i class="fa-solid fa-angle-right"></i></span>
                  <span className='w3-large bold margin-0'>Curricurum Vitae</span>
              </Link>
              <Link to='/service' className='text-dec-none w3-text-grey flex-row bold w3-hover-text-blue'>
                  <span className='w3-large margin-0 w3-padding'><i class="fa-solid fa-angle-right"></i></span>
                  <span className='w3-large bold margin-0'>Website Development</span>
              </Link>
              <Link to='/service' className='text-dec-none w3-text-grey flex-row bold w3-hover-text-blue'>
                  <span className='w3-large margin-0 w3-padding'><i class="fa-solid fa-angle-right"></i></span>
                  <span className='w3-large bold margin-0'>Digital Marketing</span>
              </Link>
              <Link to='/service' className='text-dec-none w3-text-grey flex-row bold w3-hover-text-blue'>
                  <span className='w3-large margin-0 w3-padding'><i class="fa-solid fa-angle-right"></i></span>
                  <span className='w3-large bold margin-0'>Cyber Services</span>
              </Link>
            </div>
          </div>
        </div>
        <div style={{width: '100vw'}} className="w3-continer w3-border-top grid-center">
          <div className="w3-panel">
            <p className="w3-medium margin-0 w3-text-grey">Copyright &copy; 2024 <span className='w3-text-blue bold'>Enky Tech Solutions</span> || Built By BazarinTech</p>
          </div>
        </div>
    </div>
  )
}

export default Footer