import React, { useState } from 'react'
import academic from '../images/academic.png'
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Toast, useDisclosure, useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';

const Checkout = (props) => {
  const [option, setOption] = useState();
  const [isLoading, setIsLoading] = useState(false)
  const [postData, setPostData] = useState({
    productID: '',
    name: '',
    phone: '',
    email:'',
    paymentType: ''
  })
  const toast = useToast()
  const navigate = useNavigate()
  const processToast = (status, msg) => {
    const examplePromise = new Promise((resolve, reject) => {
      setTimeout( () => {
        if (status == "Success") {
          resolve(200)
        }else{
          reject(200)
        }
      }, 2000)
      
    })

    toast.promise(examplePromise, {
      success: { title: 'Order Placed', description: msg },
      error: { title: 'An Error Occured', description: msg },
      loading: { title: 'Placing your order', description: 'Please wait' },
    })
  }

  function convertToObject(schemaStr) {
    // First, clean up the schema string
    const correctedStr = schemaStr
      .replace(/'/g, '"') // Replace single quotes with double quotes
      .replace(/([{,]\s*)(\w+)\s*:/g, '$1"$2":') // Add double quotes around keys
      .replace(/\r?\n|\r/g, ''); // Remove any newline characters
  
    // Parse the corrected string into a JavaScript object
    try {
      const obj = JSON.parse(correctedStr);
      return obj;
    } catch (error) {
      console.error("Error parsing schema:", error);
      return null;
    }
  }
  const inputs = convertToObject(typeof props.formData.inputs === 'string' 
    ? props.formData.inputs 
    : JSON.stringify(props.formData.inputs)
);

  const handleEmail = (e) => {
    setPostData({...postData, email: e.target.value, productID: props.formData.productID})
  }
  const handleName = (e) => {
    setPostData({...postData, name: e.target.value})
  }
  const handlePhone = (e) => {
    setPostData({...postData, phone: e.target.value})
  }
  const handleSelect = (e) => {
    setPostData({...postData, paymentType: e.target.value})
  }

  const placeOrder = async() => {
    setIsLoading(true)
    try {
      const response = await fetch('https://enky.xgramm.com//enky_backend/mains/order.php', {
        method: 'Post',
        headers: {
          'Content-Type': 'application/json' // Correctly setting the Content-Type header
        },
        body: JSON.stringify(postData)
      })
      const results = await response.json()
      processToast(results.Status, results.Message)
      setTimeout(() => {
        setIsLoading(false)
        if (results.Status == "Success") {
          const formInfo = {
            inputs: inputs,
            trackingID: results.TrackingID
          }
          localStorage.setItem('formData', JSON.stringify(formInfo))
          navigate('/information')
        }
      }, 5000)
      console.log(results)
    } catch (error) {
      console.error("An Error for submitting order:", error)
      setTimeout(setIsLoading(false), 3000)
    }
  }
  return(
    <Modal isOpen={props.isOpen} onClose={props.onClose} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Service Application</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="w3-row">
            <div className="w3-half">
              <div className="w3-row">
                <p className="w3-text-grey bold margin-0">Submission Details</p>
                {/* Inputs */}
                <div className="w3-panel">
                  <p className='margin-0'>Email <span className='w3-text-red'>*</span></p>
                  <input style={{width: '100%'}} type="email" onChange={handleEmail} placeholder='Enter valid email here' name='email' className='w3-border w3-round w3-border-grey w3-padding outline-none'/>
                </div>
                <div className="w3-panel">
                  <p className='margin-0'>Full Name <span className='w3-text-red'>*</span></p>
                  <input style={{width: '100%'}} type="text" onChange={handleName} placeholder='Enter full names here' name='name' className='w3-border w3-round w3-border-grey w3-padding outline-none'/>
                </div>
                <div className="w3-panel">
                  <p className='margin-0'>Phone Number <span className='w3-text-red'>*</span></p>
                  <input style={{width: '100%'}} type="phone" onChange={handlePhone} placeholder='Enter valid phone number' name='phone' className='w3-border w3-round w3-border-grey w3-padding outline-none'/>
                </div>
              </div>
            </div>
            <div className="w3-half">
              <div className="w3-row">
                <p className="w3-text-grey bold margin-0">Payment Details</p>
                <p className="margin-0">Total Cost: Kes {props.formData?.cost}</p>
                <div className="w3-panel">
                  <p className='margin-0'>How do You want to pay? <span className='w3-text-red'>*</span></p>
                  <Select placeholder='Select an option' onChange={handleSelect}>
                    <option value='before'>Pay Before Submission</option>
                    <option value='after'>Pay after submission</option>
                  </Select>
                </div>
              </div>
              <div className="w3-row">
                <p className='w3-text-grey bold'>Applying For:</p>
                {/* <p className="w3-small">Email:{postData.email}</p>
                <p className="w3-small">Name:{postData.name}</p>
                <p className="w3-small">Phone:{postData.phone}</p>
                <p className="w3-small">Payment:{postData.paymentType}</p>
                <p className="w3-small">ID:{postData.productID}</p> */}
                <div className="w3-row">
                  <div className="w3-panel w3-border w3-round w3-border-grey flex-row">
                    <div className="w3-row grid-center">
                      <img src={`https://media.xgramm.com/enky/enkyproducts/${props.formData?.banner}`} alt="" width={100} />
                    </div>
                    <div className="w3-row">
                      <p className="w3-text-grey bold margin-0">{props.formData?.name}</p>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button bg='tomato' color='white' mr={3} onClick={props.onClose}>
            Close
          </Button>
          {isLoading && <Button colorScheme='blue' isDisabled >Processing...</Button>}
          {!isLoading && <Button colorScheme='blue' onClick={placeOrder}>Proceed</Button>}
          
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}


export default Checkout