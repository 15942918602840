import React from 'react'

const Hero = (props) => {
  return (
    <div className="w3-container">
    <div className='w3-panel'>
      <div className='w3-half'>
        <div className='w3-row'>
          <p className='w3-xxxlarge margin-0 bold'>{props.name}</p>
          <p className='w3-text-grey bold w3-medium margin-0'>{props.description}</p>
        </div>
      </div>
      <div className='w3-half grid center'>
        <img src={props.image} width={400} />
      </div>
    </div>
  </div>
  )
}

export default Hero