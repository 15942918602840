import React from 'react'
import Checkout from './checkout'
import { useDisclosure } from '@chakra-ui/react'

const Products = (props) => {
  return (
    <div className='w3-col l4 m4 s12 w3-padding'>
        <div style={{backgroundColor: 'rgb(245, 246, 247)'}} className="w3-panel w3-round-large">
            <div className='w3-row grid-center'>
                    <img src={props.image} width={130}/>
                </div>
                <div className='w3-row'>
                    <p className='w3-large bold margin-0'>{props.name}</p>
                    <p className='w3-large w3-text-grey'>{props.description}</p>
                </div>
                <div className='w3-row grid-center'>
                    <button style={{width: '100%'}} className='btn secondary w3-text-white w3-margin bold w3-hover-blue' onClick={() => props.handleBtn(props.productID)}>Order Now</button>
                </div>
            </div>
    </div>
  )
}

export default Products